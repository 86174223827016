// @ts-nocheck
// TODO: fix type errors

import {
	AnyAction,
	createAction,
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
	EntityAdapter,
	EntityId,
	EntitySelectors,
	EntityState,
	PayloadAction,
	Reducer,
	Slice,
	SliceCaseReducers,
} from '@reduxjs/toolkit';

import {
	CRUDBase,
	GenerateCardMeta,
	GenerateCodesMeta,
	GetAllMeta,
} from '../services/services.base';
import { castDraft, Draft } from 'immer';
import { BaseSlice } from './interfaces/slices.base.interfaces';

import {
	BaseSliceState,
	DeleteById,
	ExtraReducesReturn,
	GenericBaseSliceState,
	NamedExtraReducersReturn,
	ReExtraReducesReturn,
} from '@Types/slices';
import { RootState } from '@Features/store';
import { BaseModel } from '@Services/generics/response';
import store from '@Features/store';
import { AxiosError } from 'axios';
type BaseSliceName = keyof RootState;

const assignFetchAll = createAction<[]>('fetch/assignFetchAll');
export abstract class BaseSliceController<
	T,
	TName extends BaseSliceName,
	P = T,
	R = T
> implements BaseSlice<T, TName, P, R>
{
	SLICE_NAME: string = '';
	initialState: BaseSliceState<R>;

	protected constructor(name: BaseSliceName, Requester: CRUDBase<P, R>) {
		this.crudAdapter = createEntityAdapter<R>({
			selectId: (model: BaseModel) => (model.id ?? model.uuid) as EntityId,
		});

		this.entitySelector = this.crudAdapter.getSelectors<RootState>(
			(state) => state.TestQuestions.entityState as unknown as EntityState<R>
		);
		this.initialState = {
			errors: {},
			entityState: {
				ids: [],
				entities: this.crudAdapter.getInitialState().entities,
			},
			currentEntity: {} as R,
			fetchAll: [],
			filters: [],
			loaders: {},
			meta: null,
			requestStatus: {},
			dateRange: {},
			selectedFilters: [],
		};
		this.SLICE_NAME = name;
		this.extraActions = {
			getAll: createAsyncThunk(
				`${this.SLICE_NAME}/all`,
				async (args: GetAllMeta | number, { rejectWithValue }) => {
					try {
						if (typeof args === 'number') {
							const { data } = await Requester.getAll(args);
							return data;
						} else {
							const { data } = await Requester.getAll(args);
							return data;
						}
					} catch (error) {
						console.log(error);
						return rejectWithValue(error);
					}
				}
			),
			generate: createAsyncThunk(
				`${this.SLICE_NAME}/generate`,
				async (
					payload: GenerateCodesMeta | GenerateCardMeta,
					{ dispatch, getState }
				) => {
					if (!Requester.generate) return;
					const { data, headers, status } = await Requester.generate(payload);
					if (status === 200) {
						this.reFetchGetAll();
					}
					return {
						headers,
						response_data: data,
					};
				}
			),
			deleteById: createAsyncThunk(
				`${this.SLICE_NAME}/delete`,
				async (payload: DeleteById) => {
					let _status;
					if (typeof payload === 'object') {
						const { data, status } = await Requester.delete(
							payload.id,
							payload.payload
						);
						_status = status;
						return data;
					} else {
						const { data, status } = await Requester.delete(payload);
						_status = status;
						if (_status === 200) {
							this.reFetchGetAll();
						}
						return data;
					}
				}
			),
			toggleActive: createAsyncThunk(
				`${this.SLICE_NAME}/update`,
				async (admin: P) => {
					if (!Requester.toggleActive) return;
					const { data } = await Requester.toggleActive(admin);
					return data;
				}
			),
			exportData: createAsyncThunk(
				`${this.SLICE_NAME}/export`,
				async (arg: GetAllMeta) => {
					if (!Requester.export) return;
					const data = await Requester.export({ ...arg, export: true });
					return { headers: data.headers, response_data: data.data };
				}
			),
			getSingle: createAsyncThunk(
				`${this.SLICE_NAME}/getSingle`,
				async ({ id, tId, payload }, { rejectWithValue }) => {
					try {
						const { data } = await Requester.get(id, tId, payload);
						return data;
					} catch (error) {
						return rejectWithValue(error);
					}
				}
			),
			process: createAsyncThunk(`${this.SLICE_NAME}/process`, async (id) => {
				if (!Requester.process) return;
				const { data, status } = await Requester.process(id);
				if (status === 200) {
					this.reFetchGetAll();
				}
				return data;
			}),
			update: createAsyncThunk(
				`${this.SLICE_NAME}/updateItem`,
				async (item: P, { rejectWithValue }) => {
					try {
						const { data } = await Requester.update(item);
						return data;
					} catch (e) {
						return rejectWithValue(e);
					}
				}
			),
			create: createAsyncThunk<
				ApiResponse<R>,
				Omit<P, 'id'>,
				{ rejectValue: AxiosError }
			>(
				`${this.SLICE_NAME}/create`,
				async (payload: Omit<P, 'id'>, { rejectWithValue }) => {
					try {
						const { data } = await Requester.create(payload);
						return data;
					} catch (error) {
						return rejectWithValue(error);
					}
				}
			),
			assignFetchAll: createAction<R[]>(`${this.SLICE_NAME}/assignFetchAll`),
		};

		this.createBaseSlice = createSlice({
			name: this.SLICE_NAME,
			initialState: this.initialState,
			reducers: {
				assignFetchAll: (state, action: PayloadAction<R[]>) => {
					state.fetchAll = castDraft(action.payload);
				},
			},
			extraReducers: (builder) => {
				builder
					.addCase(this.extraActions.getAll.pending, (state) => {
						state.loaders.fetchAll = 'pending';
						state.requestStatus.fetchAll = null;
					})
					.addCase(this.extraActions.getAll.fulfilled, (state, action) => {
						state.requestStatus.fetchAll = 'DONE';
						state.meta = action.payload.meta;
						state.filters = action.payload.filters;
						state.fetchAll = castDraft(action.payload.data);
						if (typeof action.meta.arg === 'number') {
						} else {
							state.dateRange = {
								from: action.meta.arg.from,
								to: action.meta.arg.to,
							};
							state.selectedFilters = action.meta.arg.filters;
							state.query = action.meta.arg.query;
							state.sortBy = action.meta.arg.sortBy;
						}
						state.loaders.fetchAll = 'fulfilled';
					})
					.addCase(this.extraActions.getAll.rejected, (state) => {
						state.loaders.fetchAll = 'rejected';
						state.requestStatus.fetchAll = 'FAILED';
						state.errors.fetchAll = `Couldn't get ${name}`;
					});
				builder
					.addCase(this.extraActions.exportData.pending, (state) => {
						state.loaders.export = 'pending';
					})
					.addCase(
						this.extraActions.exportData.fulfilled,
						(state, { payload }) => {
							this.generateCSVFile(payload);
							state.loaders.export = 'fulfilled';
							state.requestStatus.export = 'DONE';
						}
					)
					.addCase(this.extraActions.exportData.rejected, (state) => {
						state.loaders.export = 'rejected';
						state.requestStatus.export = 'FAILED';
					});
				builder
					.addCase(this.extraActions.deleteById.pending, (state) => {
						state.loaders.deleteById = 'pending';
					})
					.addCase(
						this.extraActions.deleteById.fulfilled,
						(state, { meta: { arg } }) => {
							const newArr = Array.from(state.fetchAll) as Array<BaseModel>;
							const isId = Number(arg.toString());
							const key = isNaN(isId) ? 'uuid' : 'id';
							const index = newArr.findIndex((_) => _[key] === arg);
							newArr.splice(index, 1);
							state.fetchAll = castDraft(newArr as Array<R>);
							state.loaders.deleteById = 'fulfilled';
							state.requestStatus.deleteById = 'DONE';
						}
					)
					.addCase(this.extraActions.deleteById.rejected, (state) => {
						state.loaders.deleteById = 'rejected';
						state.requestStatus.deleteById = 'FAILED';
					});
				builder
					.addCase(this.extraActions.toggleActive.pending, (state) => {
						state.loaders.toggleActive = 'pending';
					})
					.addCase(
						this.extraActions.toggleActive.fulfilled,
						(state, { meta, payload }) => {
							if (!payload) return;
							const { data } = payload;
							const newArr = Array.from(state.fetchAll) as Array<BaseModel>;
							const entity = data as BaseModel;
							const index = newArr.findIndex((_) => _.id === entity.id);
							newArr[index] = data;
							state.fetchAll = castDraft(newArr as Array<R>);
							state.loaders.deleteById = 'fulfilled';
							state.requestStatus.deleteById = 'DONE';
						}
					)
					.addCase(this.extraActions.toggleActive.rejected, (state) => {
						state.loaders.toggleActive = 'rejected';
						state.requestStatus.toggleActive = 'FAILED';
					});
				builder
					.addCase(this.extraActions.generate.pending, (state) => {
						state.loaders.generate = 'pending';
					})
					.addCase(
						this.extraActions.generate.fulfilled,
						(state, { payload }) => {
							this.generateCSVFile(payload);
							state.loaders.export = 'fulfilled';
							state.requestStatus.export = 'DONE';

							state.loaders.generate = 'fulfilled';
							state.requestStatus.generate = 'DONE';
						}
					)
					.addCase(this.extraActions.generate.rejected, (state) => {
						state.loaders.generate = 'rejected';
						state.requestStatus.generate = 'FAILED';
					});
				builder
					.addCase(this.extraActions.getSingle.pending, (state) => {
						state.loaders.getSingle = 'pending';
						state.errors.getSingle = null;
					})
					.addCase(
						this.extraActions.getSingle.fulfilled,
						(state, { payload }) => {
							state.currentEntity = castDraft(payload.data);
							state.loaders.getSingle = 'fulfilled';
							state.requestStatus.getSingle = 'DONE';
						}
					)
					.addCase(this.extraActions.getSingle.rejected, (state) => {
						state.loaders.getSingle = 'rejected';
						state.requestStatus.getSingle = 'FAILED';
					});
				builder
					.addCase(this.extraActions.process.pending, (state) => {
						state.loaders.process = 'pending';
						state.requestStatus.process = null;
					})
					.addCase(this.extraActions.process.fulfilled, (state) => {
						state.loaders.process = 'fulfilled';
						state.requestStatus.process = 'DONE';
					})
					.addCase(this.extraActions.process.rejected, (state) => {
						state.loaders.process = 'rejected';
						state.requestStatus.process = 'FAILED';
					});
				builder
					.addCase(this.extraActions.update.pending, (state) => {
						state.loaders.update = 'pending';
						state.requestStatus.update = null;
						state.errors.update = null;
					})
					.addCase(
						this.extraActions.update.fulfilled,
						(state, { meta, payload }) => {
							const { data } = payload;
							const newArr = Array.from(state.fetchAll) as Array<BaseModel>;
							const entity = data as BaseModel;
							const index = newArr.findIndex((_) => _.id === entity.id);
							newArr[index] = data;
							state.fetchAll = castDraft(newArr as Array<R>);
							state.currentEntity = entity as Draft<R>;
							state.loaders.update = 'fulfilled';
							state.requestStatus.update = 'DONE';
						}
					)
					.addCase(this.extraActions.update.rejected, (state) => {
						state.loaders.update = 'rejected';
						state.requestStatus.update = 'FAILED';
						state.errors.update = "Couldn't update item";
					});
				builder
					.addCase(this.extraActions.create.pending, (state) => {
						state.loaders.create = 'pending';
						state.errors.create = null;
						state.requestStatus.create = undefined;
					})
					.addCase(
						this.extraActions.create.fulfilled,
						(state, { payload: { data } }) => {
							state.createdEntity = castDraft(data);
							state.requestStatus.create = 'DONE';
							state.loaders.create = 'fulfilled';
						}
					)
					.addCase(this.extraActions.create.rejected, (state) => {
						state.requestStatus.create = 'FAILED';
						state.loaders.create = 'rejected';
					});
			},
		});
		this.getActions = () => {
			return this.generateActions();
		};
	}

	crudAdapter: EntityAdapter<R>;
	entitySelector: EntitySelectors<R, RootState>;

	extraActions: ExtraReducesReturn<P, P, R>;
	getActions: () => NamedExtraReducersReturn<T, TName, P, R>;

	generateActions = (): ReExtraReducesReturn<TName> => {
		const {
			getAll,
			deleteById,
			generate,
			toggleActive,
			update,
			exportData,
			getSingle,
			process,
			create,
			assignFetchAll,
		} = this.extraActions;

		const normalizedName =
			this.SLICE_NAME[0].toLowerCase() + this.SLICE_NAME.substring(1);

		return {
			[`${normalizedName}GetAll`]: getAll,
			[`${normalizedName}DeleteById`]: deleteById,
			[`${normalizedName}Generate`]: generate,
			[`${normalizedName}ToggleActive`]: toggleActive,
			[`${normalizedName}Update`]: update,
			[`${normalizedName}ExportData`]: exportData,
			[`${normalizedName}GetSingle`]: getSingle,
			[`${normalizedName}Process`]: process,
			[`${normalizedName}Create`]: create,
			[`${normalizedName}AssignFetchAll`]: assignFetchAll,
		} as ReExtraReducesReturn<TName>;
	};

	createBaseSlice: Slice<
		BaseSliceState<R>,
		SliceCaseReducers<BaseSliceState<R>>,
		string
	>;

	getSlice: () => Slice<
		BaseSliceState<R>,
		SliceCaseReducers<BaseSliceState<R>>,
		string
	> = () => {
		return this.createBaseSlice;
	};
	getReducer: () => Reducer<BaseSliceState<R>, AnyAction> = () =>
		this.createBaseSlice.reducer;

	private generateCSVFile = (payload: any) => {
		if (payload) {
			const { headers, response_data } = payload;
			const dataStr = 'data:text/csv;charset=utf-8,' + response_data;
			const downloadAnchorNode = document.createElement('a');
			downloadAnchorNode.setAttribute('href', dataStr);
			downloadAnchorNode.setAttribute(
				'download',
				headers['content-disposition'].split('=')[1]
			);
			document.body.appendChild(downloadAnchorNode); // required for firefox
			downloadAnchorNode.click();
			downloadAnchorNode.remove();
			return true;
		}
		return false;
	};

	private reFetchGetAll = () => {
		const state = store.getState() as RootState;
		const dispatch = store.dispatch;
		const inState = state[this.SLICE_NAME as keyof RootState];
		const { dateRange, selectedFilters, meta, sortBy, query } =
			inState as GenericBaseSliceState;

		dispatch(
			this.extraActions.getAll({
				page: meta?.current_page ?? 1,
				perPage: meta?.per_page ?? 10,
				filters: selectedFilters,
				query: query ?? '',
				sortBy,
				from: dateRange.from,
				to: dateRange.to,
				export: false,
			})
		);
	};
}
