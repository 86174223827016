import React, { useEffect, useRef, useState } from 'react';
import styled, { CSSObject } from 'styled-components';
import AdmissionListItem from './AdmissionListItem';
import { useTypedSelector } from '@Features/store';
import { useLazyClassroomResponsesListQuery } from '@Features/classrooms/InstructorAdmissionsApi';
import { useAdmissionsContext } from '@Pages/ClassRooms/Pages/SingleClassroom/Components/Admissons/Admissions';
import { AxiosResponse } from 'axios';
import { Alert, AlertTitle } from '@mui/material';
import useDebounce from '@Hooks/useDebounce';
import { CircleSpinner, ImpulseSpinner } from 'react-spinners-kit';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import moment from 'moment';
// import dayjs from 'dayjs'

const dark = '#251B33';
const light = '#fff';

const AdmissionFormList: React.FC = () => {
	const currentClassroom = useTypedSelector(
		(state) => state.classroom.currentClassroom
	);
	const nextPage = useRef<number>(1);
	const lastPage = useRef<number | undefined>(undefined);
	const [isIntersecting, setIsIntersecting] = useState<boolean>(false);
	const [cumulatedResult, setCumulatedResult] = useState<
		ClassroomResponsesListItem[]
	>([]);
	const { admissionsStatusQueryParam } = useAdmissionsContext();

	const [intersectingDiv, setIntersectingDiv] = useState<HTMLDivElement | null>(
		null
	);
	const searchDebounce = useDebounce<string>('');

	const [trigger, responsesQuery] = useLazyClassroomResponsesListQuery();

	const inputRef = useRef<HTMLInputElement>(null);

	const onSearchClick = () => {
		inputRef.current?.focus();
	};

	const searchChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		searchDebounce.setValue(event.target.value);
	};

	const fetchNextPage = async (page?: number) => {
		try {
			const lastPage_ = responsesQuery?.data?.meta?.last_page as number;
			if (!currentClassroom?.id || lastPage_ < nextPage.current) return;
			const result = await trigger({
				id: currentClassroom?.id!,
				page: page ?? nextPage.current,
				perPage: 10,
				status: admissionsStatusQueryParam,
				query: searchDebounce.debouncedValue,
			}).unwrap();
			nextPage.current = (result.meta as ResponseMeta).current_page + 1;
			setCumulatedResult([...cumulatedResult, ...result.data]);
		} catch (e) {
			console.log(e);
		}
	};

	const filtersResetHandler = async () => {
		try {
			if (!currentClassroom?.id || lastPage.current === nextPage.current)
				return;
			const result = await trigger({
				id: currentClassroom?.id!,
				page: 1,
				perPage: 10,
				status: admissionsStatusQueryParam,
				query: searchDebounce.debouncedValue,
			}).unwrap();
			nextPage.current = (result.meta as ResponseMeta).current_page + 1;
			setCumulatedResult([...result.data]);
		} catch (e) {
			console.log(e);
		}
	};

	// intersection observer side effect
	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				// isIntersecting.current = entry.isIntersecting
				setIsIntersecting(entry.isIntersecting);
				if (entry.isIntersecting) {
					console.log('fetching next page', nextPage.current);
					// fetchNextPage()
				}
			},
			{
				root: null,
				rootMargin: '0%',
				threshold: 1,
			}
		);
		if (intersectingDiv) {
			observer.observe(intersectingDiv);
		}

		return () => {
			observer.disconnect(); // observer clean up
		};
	}, [intersectingDiv, currentClassroom?.id]);

	useEffect(() => {
		filtersResetHandler();
	}, [admissionsStatusQueryParam, searchDebounce.debouncedValue]);

	useEffect(() => {
		lastPage.current = responsesQuery?.data?.meta?.last_page;
	}, [responsesQuery]);

	useEffect(() => {
		console.log({ isIntersecting });
		if (isIntersecting) fetchNextPage();
	}, [isIntersecting]);

	const mode = admissionsStatusQueryParam === 'pending' ? 'light' : 'dark';

	if (responsesQuery.isError && responsesQuery.error)
		return (
			<StyledAdmissionsList mode={mode}>
				<Alert severity="error">
					<AlertTitle>Error</AlertTitle>
					{(responsesQuery?.error as AxiosResponse<ApiResponse<undefined>>)
						?.data?.message ??
						'can not load admissions list for this classroom'}
				</Alert>
			</StyledAdmissionsList>
		);

	return (
		<StyledAdmissionsList mode={mode}>
			<StickySection>
				<StyledHeader>
					<StyledAdmissionsTitle>List Of Requests</StyledAdmissionsTitle>
					<StyledCountWrapper>
						<EdIcon>article</EdIcon>
						<StyledCount>
							{responsesQuery.data?.meta?.total ?? 0} Requests
						</StyledCount>
					</StyledCountWrapper>
				</StyledHeader>
				<StyledSearchInputGroup mode={mode} onClick={onSearchClick}>
					<StyledSearchInput
						ref={inputRef}
						mode={mode}
						value={searchDebounce.value}
						onChange={searchChangeHandler}
						placeholder="search admissions..."
					/>
					{searchDebounce.isDebouncing ? (
						<CircleSpinner size={12} color="#0e84f8" />
					) : (
						<EdIcon>search</EdIcon>
					)}
				</StyledSearchInputGroup>
				<Divider />
			</StickySection>

			<Body>
				{cumulatedResult.map((response) => (
					<AdmissionListItem
						key={response.id}
						id={response.id}
						username={response.user.username}
						studentName={`${response.user.first_name} ${response.user.last_name}`}
						date={moment(response.created_at).format('D/M/YYYY')}
					/>
				))}

				{cumulatedResult.length === 0 && (
					<Alert severity="info">No admission requests found</Alert>
				)}

				{responsesQuery.isFetching && (
					<ImpulseLoaderContainer>
						<ImpulseSpinner frontColor={mode === 'dark' ? light : dark} />
					</ImpulseLoaderContainer>
				)}

				{/*  div that is subscribed to intersection observer to automatically fire request with new page  */}
				<div ref={setIntersectingDiv} />
			</Body>
		</StyledAdmissionsList>
	);
};

const StyledAdmissionsList = styled.div<{ mode: string }>`
	height: 682px;
	border-radius: 10px;
	box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
	overflow: auto;
	background-color: ${(props) => (props.mode === 'dark' ? '#251B33' : light)};
	color: ${(props) => (props.mode === 'dark' ? light : dark)};
`;

const StickySection = styled.div`
	position: sticky;
	top: 0;
	background-color: inherit;
	color: inherit;
	padding: 26px;
`;

const Body = styled.div`
	padding: 26px;
`;

const StyledHeader = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
`;

const StyledAdmissionsTitle = styled.h2`
	font-size: 26px;
	font-weight: 600;
	letter-spacing: 0.17px;
	//color: #2F2E41;
`;

const StyledCountWrapper = styled.div`
	text-align: center;
`;

const StyledCount = styled.p`
	font-size: 10px;
	letter-spacing: 0.08px;
	//color: #2F2E41;
`;

const StyledIcon = styled(EdIcon)``;

const StyledSearchInputGroup = styled.div<{ mode: string }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid ${(props) => (props.mode === 'dark' ? light : '#251B33')};
	border-radius: 35px;
	padding-left: 20px;
	padding-right: 10px;
	margin-top: 14px;

	:hover {
		cursor: text;
	}

	:focus-within {
		border: 1px solid ${(props) => (props.mode === 'dark' ? light : '#402b5d')};
	}
`;

const StyledSearchInput = styled.input<{ mode: string }>`
	border: none;
	flex-grow: 1;
	padding: 12px 0;
	background-color: transparent;

	:focus {
		outline: none;
	}

	::placeholder {
		color: ${(props) => (props.mode === 'dark' ? light : dark)};
	}
`;

const StyledSearchIcon = styled.img``;

const Divider = styled.div`
	width: 100%;
	height: 1px;
	border-bottom: 1px solid;
	margin-top: 10px;
	//margin-bottom: 16px;
`;

const ImpulseLoaderContainer = styled.div`
	margin: 16px 0;
	display: flex;
	justify-content: center;
`;

export default AdmissionFormList;
