import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	Modal,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material';
import React from 'react';

type Props = {
	onEnroll: any;
	handleClose: () => void;
	openEnrolled: boolean;
	setDeduct: any;
	modelText?: string;
};

const EnrollementModel: React.FC<Props> = ({
	handleClose,
	onEnroll,
	openEnrolled,
	setDeduct,
	modelText,
}) => {
	const onDeductChange = (e: any) => {
		setDeduct(e.target.value);
	};

	return (
		<Modal
			open={openEnrolled}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: '600px',
					minHeight: '170px',
					borderRadius: '4px',
					border: '0',
					boxShadow: '0 3px 3px 0 rgba(0, 0, 0, 0.38)',
					margin: '39px 80px 50px 11.4px',
					padding: '12px 0 0',
					backgroundColor: '#ffffff',
					outline: 'none',
				}}
			>
				<Typography
					id="modal-modal-title"
					sx={{
						width: '552px',
						marginLeft: '24px',
						fontFamily: 'Roboto',
						fontSize: '16px',
						marginTop: '20px',
						color: 'rgba(0, 0, 0, 0.6)',
					}}
				>
					{`Are you sure you want to enroll this ${
						modelText ?? 'user'
					} in this particular course?`}
				</Typography>
				<Box sx={{ marginLeft: '24px', marginTop: '10px' }}>
					<FormControl>
						<RadioGroup name="deduct_choice" id="deduct_choice1">
							<FormControlLabel
								control={<Radio />}
								onChange={onDeductChange}
								id="deduct_choice1"
								label={`Deduct from ${modelText ?? 'User'}`}
								value={true}
								sx={{ marginLeft: '24px' }}
							/>
							<FormControlLabel
								id="deduct_choice2"
								onChange={onDeductChange}
								control={<Radio />}
								value={false}
								sx={{ marginLeft: '24px' }}
								label={`Don't deduct from ${modelText ?? 'User'}`}
							/>
						</RadioGroup>
					</FormControl>
				</Box>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end',
						marginBottom: '8px',
						outline: 'none',
					}}
				>
					<Button
						variant="text"
						sx={{
							marginTop: '8px',
							padding: '6px 16px',
							color: '#ff8532',
							outline: 'none',
							backgroundColor: '#fff',
						}}
						onClick={() => handleClose()}
					>
						disagree
					</Button>
					<Button
						variant="text"
						sx={{
							marginTop: '8px',
							padding: '6px 16px',
							color: '#6c63ff',
							backgroundColor: '#fff',
							outline: 'none',
						}}
						onClick={onEnroll}
					>
						Agree
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};

export default EnrollementModel;
