import React, { useState } from 'react';
import styled from 'styled-components';
import QuestionAnswerText from './QuestionAnswerText';
import { Button, Dialog, DialogContent, Box } from '@mui/material';
import {
	AdmissionQuestionContainer,
	AdmissionQuestion,
	AdmissionQuestionType,
} from './AdmissionQuestions.styled';

type Props = {
	question: string;
	answer: string;
};

export const AdmissionUploadView: React.FC<Props> = ({ question, answer }) => {
	const [showAnswer, setShowAnswer] = useState<boolean>(false);

	const onCloseHandler = (event: object, reason: string) => {
		console.log({ reason });
		if (reason === 'backdropClick') setShowAnswer(false);
	};

	return (
		<AdmissionQuestionContainer>
			<AdmissionQuestion>Q1. {question}</AdmissionQuestion>
			<AdmissionQuestionType>upload</AdmissionQuestionType>
			{QuestionAnswerText}
			<Button
				style={{
					color: '#6C63FF',
					borderColor: '#6C63FF',
					width: 'fit-content',
				}}
				variant="outlined"
				onClick={() => setShowAnswer(true)}
			>
				View
			</Button>

			{showAnswer && (
				<Dialog
					open={showAnswer}
					onClose={onCloseHandler}
					maxWidth="md"
					fullWidth
				>
					<DialogContent>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<StyledIframe src={answer} />
						</Box>
					</DialogContent>
				</Dialog>
			)}
		</AdmissionQuestionContainer>
	);
};

const IframeContainer = styled.div`
	width: fit-content;
	background-color: #00bbff;
	margin: 0 auto;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const StyledIframe = styled.iframe`
	width: 100%;
	height: 500px;
`;

export default AdmissionUploadView;
