import { Button, IconButton, Paper, TextField } from '@mui/material';
import { rgba } from 'polished';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FlexLayout, Spacer } from '@Styled/utilities';
import {
	ContentInfo,
	ContentType,
} from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Types/CourseContent.types';
import { ContentConfig } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Constants/CourseContent.constants';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import {
	DragDropContext,
	Draggable,
	DraggableProvided,
	Droppable,
	DropResult,
	ResponderProvided,
} from 'react-beautiful-dnd';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import { useHistory, useLocation } from 'react-router-dom';
import ContentAddMenu from '../../Components/ContentAddMenu/ContentAddMenu';
import { useSnackbar } from '@Providers/useSnackbar';
import { Unit } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Contents/Types/Units.types';
import { useForm } from 'react-hook-form';
import {
	unitsAssignFetchAll,
	unitsDeleteById,
	unitsGetAll,
	unitsToggleActive,
	unitsUpdate,
} from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Contents/Slices/Units.slice';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';
import DragButton from '@Components/UI/Buttons/EdDragButton/EdDragButton';
import EdSpeedDial, {
	EdSpeedDialAction,
} from '@Components/UI/Utilities/EdSpeedDial/EdSpeedDial';
import {
	RouteProvider,
	useRouteProvider,
} from '@Providers/Routes/RoutesProvider';
import { CourseUnit } from '@Services/classrooms/classrooms.res.types';
import { UnitsRequester } from './Services/Unit.req';
import api from '@Services/api';

const Contents = () => {
	const { currentCourse } = useTypedSelector((state) => state.classroom);
	const { fetchAll } = useTypedSelector((state) => state.Units);
	const dispatch = useAppThunkDispatch();
	const [needReorder, setNeedReorder] = useState(false);
	useEffect(() => {
		if (currentCourse) {
			dispatch(unitsGetAll(currentCourse.id));
		}
	}, [currentCourse]);

	const handleOnDragEnd = (result: DropResult, provided: ResponderProvided) => {
		if (result.destination) {
			const list = Array.from(fetchAll);
			list.splice(
				result.destination.index,
				0,
				list.splice(result.source.index, 1)[0]
			);
			setNeedReorder(true);
			dispatch(unitsAssignFetchAll(list));
		}
	};

	const { displaySnackbar } = useSnackbar();

	const handleSaveReorder = async () => {
		try {
			if (!currentCourse) return;
			await UnitsRequester.getInstance().reorderUnits({
				courseId: currentCourse.id,
				units: fetchAll.map((val, index) => ({
					id: val.id,
					name: val.name,
					order: index + 1,
				})),
			});
			setNeedReorder(false);
			displaySnackbar('success', 'Reordered units successfully');
		} catch (error) {
			displaySnackbar('error', "Couldn't reorder units");
		}
	};

	const handleCancelReorder = () => {
		if (currentCourse) {
			dispatch(unitsGetAll(currentCourse.id));
		}
	};
	return (
		<div style={{ width: '100%', display: 'grid' }}>
			<FlexLayout justifyContent="flex-end">
				<ContentAddMenu />
			</FlexLayout>
			<Spacer my="1.75rem" />

			{fetchAll && (
				<DragDropContext onDragEnd={handleOnDragEnd}>
					<Droppable droppableId="droppable">
						{({ droppableProps, innerRef, placeholder }) => {
							return (
								<div {...droppableProps} ref={innerRef}>
									{placeholder}
									{fetchAll.map((item, index) => {
										return (
											<Draggable
												shouldRespectForcePress
												key={item.id}
												draggableId={`${item.id}`}
												index={index}
											>
												{(draggableProvided: DraggableProvided) => {
													return (
														<div>
															<ContentTypePanel
																draggableProvided={draggableProvided}
																type={item.unitType.name}
																typeData={ContentConfig[item.unitType.name]}
																data={item}
															/>
														</div>
													);
												}}
											</Draggable>
										);
									})}
								</div>
							);
						}}
					</Droppable>
				</DragDropContext>
			)}
			{needReorder && (
				<>
					<Spacer my="0.5rem" />
					<FlexLayout justifyContent="flex-end">
						<Button
							onClick={handleCancelReorder}
							variant="contained"
							color="warning"
						>
							Cancel
						</Button>
						<Spacer mx="0.5rem" />
						<Button onClick={handleSaveReorder} variant="contained">
							Save
						</Button>
					</FlexLayout>
				</>
			)}
		</div>
	);
};

export default Contents;

type PanelProps = {
	type: ContentType;
	typeData: ContentInfo<ContentType>;
	data: Unit;
	draggableProvided: DraggableProvided;
};

const ContentTypePanel: FC<PanelProps> = ({
	data,
	type,
	typeData,
	draggableProvided: { draggableProps, innerRef, dragHandleProps },
}) => {
	const [showActions, setShowActions] = useState(false);
	const [edit, setEdit] = useState<boolean>(false);
	const [openActivationModel, setOpenActivationModel] =
		useState<boolean>(false);
	const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
	const dispatch = useAppThunkDispatch();
	const { displaySnackbar } = useSnackbar();
	const history = useHistory();
	const { pathname } = useLocation();
	const [dataValues, setDataValue] = useState<Unit>(data);
	const { currentCourse } = useTypedSelector((state) => state.classroom);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
	} = useForm<Unit>({
		defaultValues: dataValues,
	});

	const handleEditUnit = () => {
		if (type === 'test') {
			history.push(
				`${pathname.split('edit')[0]}test/${dataValues.test.id}/questions`
			);
		}
		if (type === 'webinar') {
			history.push(`${pathname}/webinar/${dataValues.webinar.id}`);
		}
		if (type === 'document') {
			history.push(`${pathname}/edit/document/${dataValues.document.id}`);
		}
		if (type === 'webcontent') {
			history.push(`${pathname}/content/${dataValues.content.id}`);
		}
		if (type === 'video') {
			history.push(`${pathname}/video/${dataValues.video.id}`);
		}
		if (type === 'assignment') {
			history.push(`${pathname}/assignment/${dataValues?.assignment?.id}`);
		}
	};

	const onSubmit = handleSubmit(async (form: Unit) => {
		setEdit(false);
		try {
			console.log({form})
			setDataValue(form);
			await dispatch(
				unitsUpdate({
					...form,
					// active: true,
				})
			);
			displaySnackbar('success', 'Unit Name Is Edited Successfully');
		} catch (e) {
			const msg = e.response?.data.message || 'Unable To Edit Unit Name';
			displaySnackbar('error', msg);
		}
	});

	const renderEditInput = () => {
		return (
			<form onSubmit={onSubmit}>
				<div>
					<EditContainer>
						<ContentPanelTitleEdit
							size={'small'}
							defaultValue={data?.name}
							{...register('name', { required: true })}
						/>
						<IconButton color={'primary'} type={'submit'}>
							<EdIcon mIconType={'Outlined'}>check_circle</EdIcon>
						</IconButton>
						<IconButton
							color={'warning'}
							onClick={() => {
								setEdit(false);
								reset();
							}}
							type={'reset'}
						>
							<EdIcon mIconType={'Outlined'}>cancel</EdIcon>
						</IconButton>
					</EditContainer>
					{errors.name && (
						<StyledErrorMessage>Unit name is required!</StyledErrorMessage>
					)}
				</div>
			</form>
		);
	};

	const handleActivationModelClose = async (
		close: boolean,
		confirmation?: boolean
	) => {
		setOpenActivationModel(close);
		if (confirmation) await toggleUnitActivation();
	};
	const handleActivationClick = () => {
		setOpenActivationModel(true);
	};

	const toggleUnitActivation = async () => {
		try {
			await dispatch(unitsToggleActive({ ...data, active: !data.active }));
			if (!data.active)
				displaySnackbar('success', 'Activate Unit Successfully');
			else displaySnackbar('success', 'Deactivate Unit Successfully');
		} catch (e) {
			const msg =
				e.response?.data.message || 'Unable To Change Unit Activation';
			displaySnackbar('error', msg);
		}
	};

	const handleDeleteModelClose = async (
		close: boolean,
		confirmation?: boolean
	) => {
		setOpenDeleteModel(close);
		if (confirmation) {
			try {
				dispatch(unitsDeleteById(data.id));
				displaySnackbar('success', 'Unit Is Deleted Successfully');
			} catch (e) {
				const msg = e.response?.data.message || 'Unable To Delete Unit ';
				displaySnackbar('error', msg);
			}
		}
	};
	const handleDeleteClick = () => {
		setOpenDeleteModel(true);
	};
	const handelCloneUnit = async () => {
		const body = {
			course_id: currentCourse?.id,
			unit_id: dataValues?.id,
		};
		api
			.post('clone/unit', body)
			.then(() => {
				if (currentCourse) dispatch(unitsGetAll(currentCourse.id));
			})
			.then(() => {
				displaySnackbar('success', 'Clone Unit');
			})
			.catch(function (error) {
				if (error.response)
					displaySnackbar('error', error.response.data.message);
				else displaySnackbar('error', 'Clone Error');
			});
	};
	return (
		<div
			onClick={(e) => {
				e.stopPropagation();
				setShowActions(!showActions);
			}}
			{...draggableProps}
		>
			<EdConfirmModal
				open={openActivationModel}
				handleClose={handleActivationModelClose}
				text={
					data.active
						? `Are you sure you want to deactivate ${data.name} unit`
						: `Are you sure you want to activate ${data.name} unit`
				}
			/>
			<EdConfirmModal
				open={openDeleteModel}
				handleClose={handleDeleteModelClose}
				text={`Are you sure you want to delete ${data.name} unit`}
			/>
			<RouteProvider path={pathname}>
				<ContentPanel ref={innerRef}>
					<ContentPanelSummary>
						{dragHandleProps && <DragButton dragHandlers={dragHandleProps} />}
						{typeData?.icon}
						{edit ? (
							renderEditInput()
						) : (
							<ContentPanelTitle blocked={!data.active}>
								{data.name}
							</ContentPanelTitle>
						)}
					</ContentPanelSummary>
					<EdSpeedDial ariaLabel="" FabProps={{ size: 'small' }}>
						<EdSpeedDialAction
							title="Delete"
							onClick={handleDeleteClick}
							icon={<EdIcon>delete</EdIcon>}
						/>
						<EdSpeedDialAction
							title={data.active ? 'Deactivate' : 'Activate'}
							onClick={handleActivationClick}
							icon={<EdIcon>{data.active ? 'block' : 'check'}</EdIcon>}
						/>
						<EdSpeedDialAction
							title="Clone"
							onClick={handelCloneUnit}
							icon={<EdIcon>content_copy</EdIcon>}
						/>
						<EdSpeedDialAction
							// $disabled={!data.active}
							title="Rename"
							onClick={() => {
								// if (!data.active) return;
								setEdit(true);
							}}
							icon={<EdIcon>edit_note</EdIcon>}
						/>
						<EdSpeedDialAction
							title="Edit"
							onClick={handleEditUnit}
							icon={<EdIcon>edit</EdIcon>}
						/>
					</EdSpeedDial>
				</ContentPanel>
			</RouteProvider>
		</div>
	);
};

const ContentPanelSummary = styled.div`
	display: flex;
	align-items: center;
`;

const ContentPanel = styled(Paper)`
	padding: 0 1.5rem;
	display: flex;
	align-items: center;
	margin: 0.375rem 0;
	justify-content: space-between;

	&:hover {
		background-color: #f5f5f5;
	}
`;
const ContentPanelTitle = styled.label<{ blocked: boolean }>`
	font-size: 0.875rem;
	font-weight: bold;
	margin: 0 0.688rem;
	color: ${(props) => (props.blocked ? 'gray' : 'black')};
`;
const ContentPanelTitleEdit = styled(TextField)`
	font-size: 0.875rem;
	font-weight: bold;
	margin: 5px 0.688rem 0;
`;
const EditContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const StyledErrorMessage = styled.p`
	color: red;
	font-size: 12px;
`;
