import MainTable from '@Components/MainTable';
import SidebarContentWrapper from '@Components/SidebarContentWrapper';
import {
	banStudentFromClassroom,
	getAllClassroomEnrollements,
} from '@Features/classrooms/classroomSlice';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import React, { useCallback, useState } from 'react';
import { useSnackbar } from '@Providers/useSnackbar';
import NoResults from '@Components/NoResults/NoResults';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import TableCell from '@mui/material/TableCell';
import Chip from '@mui/material/Chip';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Tooltip from '@mui/material/Tooltip';
import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import BanModel from './BanModel/BanModel';
import { TableHeader } from '@Features/generics/generics.type';
import styled from 'styled-components';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';

const userTableHeaders: Array<TableHeader> = [
	{ displayName: 'FIRST NAME', fieldName: 'first_name' },
	{ displayName: 'LAST NAME', fieldName: 'last_name' },
	{ displayName: 'EMAIL', fieldName: 'email', width: '25%' },
	{ displayName: 'USERNAME', fieldName: 'username' },
	{ displayName: 'PHONE', fieldName: 'phone_number' },
	{ displayName: 'BANNED ON', fieldName: 'created_at' },
	{ displayName: 'GROUP', fieldName: 'group_id' },
	{ displayName: 'STATUS', fieldName: 'active', canSort: false },
];

const ClassRoomSubscriptions: React.FC = () => {
	const dispatch = useAppThunkDispatch();
	const [active, setActive] = useState<boolean>(false);
	const { displaySnackbar } = useSnackbar();
	const [reload, setReload] = useState<StateLoader>('idle');
	const {
		classroomEnrollments,
		classroomEnrollementsFilters,
		lastPage,
		totalResults,
		perPage,
		page,
		loaders,
		currentClassroom,
	} = useTypedSelector((state) => state.classroom);

	const [openModel, setOpenModel] = useState(false);
	const [classroom_id, setClassId] = useState<number>(0);
	const [user_id, setUserId] = useState<number>(0);

	const handleModelOpen = (
		active: boolean,
		classId: number,
		usrtId: number
	) => {
		setOpenModel(true);
		setActive(active);
		setClassId(classId);
		setUserId(usrtId);
	};
	const handleModelClose = () => {
		setClassId(0);
		setUserId(0);
		setOpenModel(false);
	};

	const onBanStudent = async () => {
		try {
			const student = {
				classroom_id,
				user_id,
				active: !active,
			};
			await dispatch(banStudentFromClassroom(student)).unwrap();
			setReload('pending');
			if (active) {
				displaySnackbar('success', 'Banned Student successfully');
			} else {
				displaySnackbar('success', 'Activated Student successfully');
			}
			setOpenModel(false);
		} catch (error) {}
	};

	const onPageChangeRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			if (currentClassroom || reload !== 'fulfilled') {
				dispatch(
					getAllClassroomEnrollements({
						page: activePage,
						perPage: perPage ?? 10,
						classroom_id: currentClassroom?.id as number,
						filters,
						sortBy,
						query,
						from: startDate,
						to: endDate,
					})
				);
				setReload('fulfilled');
			}
		},
		[dispatch, reload, currentClassroom]
	);

	return (
		<SidebarContentWrapper>
			<BanModel
				openModel={openModel}
				handleModelClose={handleModelClose}
				ban={active}
				onConfirmation={onBanStudent}
			/>

			<MainTable
				onPageChangeRequest={onPageChangeRequest}
				totalPages={lastPage}
				tableFilters={classroomEnrollementsFilters}
				total={Number(totalResults)}
				RowsperPage={Number(perPage)}
				pageNum={Number(page)}
				tableHeads={userTableHeaders}
				renderItems={() => {
					if (classroomEnrollments.length <= 0)
						return (
							<MainTableRow>
								<TableCell colSpan={userTableHeaders.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);

					return classroomEnrollments.map((classEn) => (
						<MainTableRow
							key={classEn.classroom_id}
							hover
							sx={{
								cursor: 'pointer',
								height: '52px',
							}}
						>
							<TableCellStyled>
								{classEn?.user?.first_name || '-'}
							</TableCellStyled>

							<TableCellStyled>
								{classEn?.user?.last_name || '-'}
							</TableCellStyled>

							<TableCellStyled
								sx={{
									color: '#303f9f',
									textDecoration: 'underline',
									textDecorationColor: '#303f9f',
									textUnderlineOffset: '0.4em',
									textDecorationThickness: '1px',
								}}
							>
								<a title={classEn?.user?.email || '-'}>
									{classEn?.user?.email || '-'}
								</a>
							</TableCellStyled>

							<TableCellStyled>
								<Chip
									label={classEn?.user?.username || '-'}
									sx={{ fontSize: '13px' }}
								/>
							</TableCellStyled>

							<>
								<TableCellStyled>
									{classEn?.user?.phone_number || '-'}
								</TableCellStyled>

								<TableCellStyled>
									{classEn?.active ? (
										'-'
									) : (
										<DateTimeFormatter date={classEn?.updated_at} />
									)}
								</TableCellStyled>
								<TableCellStyled>
									{classEn.group?.name ?? '-'}
								</TableCellStyled>
								<TableCellStyled>
									<Tooltip
										placement="left"
										title={classEn.active ? 'Click to ban' : 'Click to Unban'}
									>
										<Button
											id="banUnBanUSer"
											onClick={() =>
												handleModelOpen(
													classEn.active,
													classEn.classroom_id,
													classEn.user_id
												)
											}
										>
											{!classEn.active ? (
												<RedAccountBoxIcon />
											) : (
												<BlueAccountBoxIcon />
											)}
										</Button>
									</Tooltip>
								</TableCellStyled>
							</>
						</MainTableRow>
					));
				}}
			/>
		</SidebarContentWrapper>
	);
};

export default ClassRoomSubscriptions;

const TableCellStyled = styled(TableCell)`
	font-family: Roboto;
	font-size: 1rem;
	padding: 0 1.063rem 0;
	height: 3.25rem;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

const RedAccountBoxIcon = styled(AccountBoxIcon)`
	color: #f74343;
	height: 26px;
	width: 26px;
`;
const BlueAccountBoxIcon = styled(AccountBoxIcon)`
	color: #5ac0fc;
	height: 26px;
	width: 26px;
`;
