import React from 'react';
import { Divider, FlexLayout, Spacer } from '@Styled/utilities';

import styled from 'styled-components';
import { Button } from '@mui/material';
import { useForm, FormProvider, Control, FieldValues } from 'react-hook-form';
import { TestPayload } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Types/Test/Test.types';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { TestOptionsSchema } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Schema/TestOptions.schema';
import TestOptionsMainSection from './Components/TestOptionsMainSection';
import TestOptionsGradingSection from './Components/TestOptionsGradingSection';
import TestOptionsRespondentsSection from './Components/TestOptionsRespondentsSection';
import TestOptionsRepetitionSection from './Components/TestOptionsRepetitionSection';
import TestOptionsGeneralSection from './Components/TestOptionsGeneralSection';
import TestOptionsQuestionBehaviorSection from './Components/TestOptionsQuestionBehaviorSection';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import { testsGetSingle, testsUpdate } from '../../Slice/TestSlice';
import { useSnackbar } from '@Providers/useSnackbar';
import { cloneDeep } from 'lodash';
import { useRouteProvider } from '@Providers/Routes/RoutesProvider';
import moment from 'moment';
const TestOptions = () => {
	const dispatch = useAppThunkDispatch();
	const { currentEntity } = useTypedSelector((state) => state.Tests);
	const { displaySnackbar } = useSnackbar();
	const formDefaultValues = {
		id: currentEntity?.id,
		unit: currentEntity?.unit,
		test: {
			active_start_date: currentEntity.active_start_date
				? moment(currentEntity.active_start_date).format('YYYY-MM-DDTHH:MM')
				: null,
			active_end_date: currentEntity.active_end_date
				? moment(currentEntity.active_end_date).format('YYYY-MM-DDTHH:MM')
				: null,
			active: currentEntity?.active,
			allow_movement: currentEntity?.allow_movement,
			allow_repetition_when: currentEntity?.allow_repetition_when,
			allowed_trials: currentEntity?.allowed_trials,
			created_at: currentEntity?.created_at,
			duration: currentEntity?.duration,
			end_text: currentEntity?.end_text,
			gradeSchema: currentEntity?.gradeSchema,
			id: currentEntity?.id,
			include_previous_attempts: currentEntity?.include_previous_attempts,
			locked: currentEntity?.locked,
			message_if_failed: currentEntity?.message_if_failed,
			message_if_passed: currentEntity?.message_if_passed,
			model_mode: currentEntity?.model_mode,
			overall_score: currentEntity?.overall_score,
			passing_unit: currentEntity?.passing_unit,
			passing_value: currentEntity?.passing_value,
			questions: currentEntity?.questions,
			show_correct_if_failed: currentEntity?.show_correct_if_failed,
			show_correct_if_passed: currentEntity?.show_correct_if_passed,
			show_grade: currentEntity?.show_grade,
			show_score_percentage: currentEntity?.show_score_percentage,
			show_score_value: currentEntity?.show_score_value,
			show_status: currentEntity?.show_status,
			shuffle_answers: currentEntity?.shuffle_answers,
			shuffle_questions: currentEntity?.shuffle_questions,
			start_text: currentEntity?.start_text,
			test_summary: currentEntity?.test_summary,
			title: currentEntity?.title,
			unit: currentEntity?.unit,
			unit_id: currentEntity?.unit_id,
			uuid: currentEntity?.uuid,
			updated_at: currentEntity?.updated_at,
			view_mode: currentEntity?.view_mode,
		},
		defineGradeSchema: Boolean(
			currentEntity.gradeSchema && currentEntity.gradeSchema.length > 0
		),
		showTestPeriod: Boolean(
			currentEntity.active_start_date && currentEntity.active_end_date
		),
		allowRepetition: Boolean(
			currentEntity?.allowed_trials &&
				currentEntity.allowed_trials > 0 &&
				currentEntity?.allow_repetition_when !== undefined
		),
		showCorrectAnswer: Boolean(
			currentEntity.show_correct_if_failed ||
				currentEntity.show_correct_if_passed
		),
		passOrFailMessage: Boolean(
			currentEntity.message_if_failed || currentEntity.message_if_passed
		),
	};
	const {
		control,
		handleSubmit,
		formState,
		setValue,
		getValues,
		...formMethods
	} = useForm<TestPayload>({
		mode: 'all',
		resolver: yupResolver(TestOptionsSchema),
		defaultValues: cloneDeep(formDefaultValues) as any,
	});

	const { isValid, isDirty, errors } = formState;
	const onSave = async (form: TestPayload) => {
		try {
			await dispatch(
				testsUpdate({
					...form,
					test: {
						...form.test,
						duration: form.test?.duration === 0 ? null : form.test?.duration,
					},
				})
			).unwrap();
			if (form.id) await dispatch(testsGetSingle({ id: form.id }));
			displaySnackbar('success', 'Updated Test options successfully');
		} catch (error) {
			console.log(error);
			displaySnackbar('error', error.data.message);
		}
	};
	const methods = useRouteProvider();

	return (
		<StyledContainer>
			<DevTool control={control} />
			<FormProvider
				control={control}
				handleSubmit={handleSubmit}
				formState={formState}
				getValues={getValues}
				setValue={setValue}
				{...formMethods}
			>
				<DevTool control={control} />
				{/*Section 1*/}
				<TestOptionsMainSection />

				{/*Section 2: Grading Criteria*/}
				<Divider mx={0} label="Grading Criteria" />
				<TestOptionsGradingSection />

				{/*Section 3: Info for respondents*/}
				<TestOptionsRespondentsSection />

				{/*Section 4:*/}
				<Divider mx={0} label="Repetition" />
				<TestOptionsRepetitionSection />
				{/*Section 5 :*/}
				<Divider mx={0} label="General" />

				<TestOptionsGeneralSection />
				{/*Section 5 :*/}
				<Divider mx={0} label="Question Behavior" />

				<TestOptionsQuestionBehaviorSection />
				{!currentEntity.active && (
					<FlexLayout mt="1.688rem" justifyContent="flex-end">
						<Button
							disabled={!isDirty}
							variant="contained"
							color="warning"
							onClick={() => {
								methods?.goToParent();
							}}
						>
							Cancel
						</Button>
						<Spacer mx="0.5rem" />
						<Button
							// onClick={handleSubmit(onSave, (e) => console.log(e))}
							onClick={() => {
								const data = getValues();
								onSave(data);
							}}
							disabled={!isDirty || !isValid}
							variant="contained"
						>
							Save
						</Button>
					</FlexLayout>
				)}
			</FormProvider>
		</StyledContainer>
	);
};

const StyledContainer = styled.form`
	margin-left: 2.625rem;
	align-items: center;
`;

export default TestOptions;
