import {
	DASHBOARD_BASE_ROUTE,
	RouteGroup,
	SingleRoute,
} from '@Constants/routes';
import { Button, ButtonProps } from '@mui/material';
import { rgba } from 'polished';
import React, { useContext, useEffect, useMemo } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { NavMenuContext } from '../Layout';
import { useTypedSelector } from '@Features/store';

const HeaderNavMenu = () => {
	const history = useHistory();
	const { pathname } = useLocation();
	const { currentRouteGroup, baseURL } = useContext(NavMenuContext);
	const auth = useTypedSelector((state) => state.auth);

	const handleRouting = (
		isSelected: boolean,
		route?: SingleRoute | RouteGroup
	) => {
		let _toRouteURL = baseURL;

		if ((!isSelected || route?.forceNavigate) && baseURL && currentRouteGroup) {
			if (route) history.push(`${_toRouteURL}/${route.route}`);
			else history.push(`${_toRouteURL}`);
		}
	};

	const isOverviewSelected = useMemo(() => {
		return (
			pathname === baseURL ||
			(currentRouteGroup?.overviewRoute !== undefined &&
				pathname.endsWith(currentRouteGroup.overviewRoute))
		);
	}, [pathname]);

	console.log(!parseInt(auth.user.parent_id))
	return (
		<HeaderTabsWrapper>
			{currentRouteGroup?.isRouted && currentRouteGroup?.isParent && (
				<NavButton
					onClick={() => {
						if (!isOverviewSelected) handleRouting(false);
					}}
					$Selected={isOverviewSelected}
					// variant={pathname === baseURL ? "contained" : "text"}
				>
					Overview
				</NavButton>
			)}
			{currentRouteGroup &&
				Array.isArray(currentRouteGroup.routes) &&
				currentRouteGroup?.routes.map((route, index) => {
					const isSelected = pathname.includes(route.route);
					return (
						!route.offMenu &&
						(!auth.user.parent_id || auth.grants.has(route.code)) && (
							<NavButton
								key={`${index}-${route.route}-hnm`}
								onClick={() => handleRouting(isSelected, route)}
								$Selected={isSelected}
								// variant={!isSelected ? "text" : "contained"}
							>
								{route.title}
							</NavButton>
						)
					);
				})}
		</HeaderTabsWrapper>
	);
};

export default HeaderNavMenu;
const HeaderTabsWrapper = styled.div``;

const NavButton = styled(Button)<ButtonProps & { $Selected?: boolean }>`
	text-transform: capitalize;
	font-weight: 600;
	color: ${(props) =>
		props.$Selected
			? props.theme.palette.primary.main
			: props.theme.palette.grey['900']};
	margin: 0 1.5rem;
	:first-child {
		margin-left: 0;
	}
	background-color: ${(props) =>
		props.$Selected ? rgba(props.theme.palette.primary.main, 0.2) : ''};
	:hover,
	:active {
		background: ${(props) =>
			props.$Selected
				? props.theme.palette.primary.light
				: props.theme.palette.grey.A200};
		color: ${(props) => (props.$Selected ? '#fff' : '')};
		outline: none;
		box-shadow: none;
	}
	box-shadow: none;
`;
