import MainTable from '@Components/MainTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import NoResults from '@Components/NoResults/NoResults';
import RoundButton from '@Components/UI/Buttons/RoundButton';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import EdSpeedDial, {
	EdSpeedDialAction,
} from '@Components/UI/Utilities/EdSpeedDial/EdSpeedDial';
import { TableHeader } from '@Features/generics/generics.type';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import { TableCell } from '@mui/material';
import { useSnackbar } from '@Providers/useSnackbar';
import { FlexLayout } from '@Styled/utilities';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { notesDeleteById, notesGetAll } from './Slices/Notes.Slices';
import { useRouteProvider } from '@Providers/Routes/RoutesProvider';

const TABLE_HEADS: Array<TableHeader> = [
	{ displayName: 'Name', fieldName: 'name' },
	{ displayName: 'Price', fieldName: 'price' },
	{ displayName: 'Old Price', fieldName: 'old_price' },
	{ displayName: 'Link', fieldName: 'purchase_link' },
];

const NotesTable = () => {
	const history = useHistory();
	const { displaySnackbar } = useSnackbar();
	const dispatch = useAppThunkDispatch();
	const [openModel, setOpenModel] = useState<boolean>(false);
	const [deletedUuid, setDeletedUuid] = useState<number | undefined>();
	const [needRefresh, setNeedRefresh] = useState(true);
	const { fetchAll, meta, filters, query, requestStatus } = useTypedSelector(
		(state) => state.Notes
	);

	useEffect(() => {
		if (requestStatus.deleteById === 'DONE') {
			displaySnackbar('success', 'Note delete successfully');
		} else if (requestStatus.deleteById === 'FAILED') {
			displaySnackbar('error', "Couldn't delete Note");
		}
	}, [requestStatus.deleteById]);

	const handleModelOpen = (uuid: number) => {
		console.log({ uuid });
		setOpenModel(true);
		setDeletedUuid(uuid);
	};
	const handleModelClose = async (close: boolean, confirm?: boolean) => {
		try {
			if (confirm && deletedUuid) {
				await dispatch(
					notesDeleteById({ id: deletedUuid, payload: { id: deletedUuid } })
				).unwrap();
				setDeletedUuid(undefined);
				setNeedRefresh(true);
			}
			setOpenModel(false);
		} catch (error) {}
	};

	const parentRoute = useRouteProvider();

	const onPageChangeRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			if (needRefresh)
				dispatch(
					notesGetAll({
						page: activePage,
						perPage: perPage ?? 10,
						sortBy,
						filters,
						query,
						from: startDate,
						to: endDate,
					})
				);
			setNeedRefresh(false);
		},
		[needRefresh]
	);

	return (
		<>
			<EdConfirmModal
				text="Are you sure you want to delete this Note"
				open={openModel}
				handleClose={handleModelClose}
			/>
			<FlexLayout justifyContent="flex-end">
				<RoundButton
					onClick={() => history.push(`${parentRoute?.path}/create/note`)}
					variant="contained"
				>
					Add Note
				</RoundButton>
			</FlexLayout>
			<MainTable
				total={meta?.total ?? 0}
				dateFilter
				pageNum={meta?.current_page}
				tableFilters={filters}
				tableHeads={TABLE_HEADS}
				totalPages={meta?.last_page ?? 0}
				RowsperPage={Number(meta?.per_page)}
				$hasActions
				onPageChangeRequest={onPageChangeRequest}
				renderItems={() => {
					if (fetchAll?.length === 0)
						return (
							<MainTableRow>
								<TableCell colSpan={TABLE_HEADS.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					return fetchAll?.map((note) => {
						return (
							<MainTableRow
								key={`${note.id}-${note.code}`}
								hover
								onClick={() => {
									history.push({
										pathname: `/dashboard/notes/edit/note/${note.id}`,
									});
								}}
							>
								<TableCell>{note?.name}</TableCell>
								<TableCell>{note?.price}</TableCell>
								<TableCell>{note?.old_price}</TableCell>
								<TableCell>{note?.purchase_link}</TableCell>

								<TableCell>
									<EdSpeedDial ariaLabel="SpeedDial basic example">
										<EdSpeedDialAction
											key="delete"
											icon={<EdIcon>delete</EdIcon>}
											tooltipTitle="Delete"
											onClick={(e) => {
												console.log(note);
												e.stopPropagation();
												handleModelOpen(note?.id);
											}}
										/>
									</EdSpeedDial>
								</TableCell>
							</MainTableRow>
						);
					});
				}}
			/>
		</>
	);
};

export default NotesTable;
