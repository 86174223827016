import React, { useEffect, useMemo, useState } from 'react';
import {
	FieldArrayWithId,
	UseFieldArrayAppend,
	useForm,
} from 'react-hook-form';
import styled from 'styled-components';
import { EdFormControlLabel } from '@Components/UI/Inputs/EdFormControlLabel/EdFormControlLabel';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { TestPayload } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Types/Test/Test.types';
import { yupResolver } from '@hookform/resolvers/yup';
import { GradeSchema } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Schema/TestOptions.schema';
import {
	StyledInnerContainer,
	StyledLabel,
	StyledOuterContainer,
} from './GradesArrayFields.styled';
import EdButton from '@Components/UI/Buttons/EdButton/EdButton';

type Props = {
	append: UseFieldArrayAppend<TestPayload, 'test.gradeSchema'>;
	fields: FieldArrayWithId<TestPayload, 'test.gradeSchema', 'id'>[];
};

type FormInputs = {
	to: number;
	from: number;
	grade: string;
};
const GradeOptionsStartFrom: React.FC<Props> = ({ fields, append }) => {
	const {
		register,
		control,
		handleSubmit,
		reset,
		setValue,
		getValues,
		formState: { isValid },
	} = useForm<FormInputs>({
		mode: 'all',
		resolver: yupResolver(GradeSchema),
	});

	const [disabled, setDisabled] = useState<boolean>(false);
	const fromGrade = useMemo(() => {
		console.log(fields);
		const dupArr = Array.from(fields);
		if (dupArr.length === 0) return 0;
		const lastGrade = dupArr.pop();
		if (!lastGrade?.to) return 0;
		return Number(lastGrade?.to) + 1;
	}, [fields]);

	useEffect(() => {
		setValue('from', fromGrade, { shouldValidate: true });
		if (fromGrade > 99) setDisabled(true);
		else setDisabled(false);
	}, [fromGrade]);

	const onAdd = () => {
		const tempValues = { ...getValues(), from: fromGrade };
		reset(undefined, {
			keepDirty: false,
			// keepDirtyValues: false,
			keepTouched: false,
			keepValues: false,
		});

		append(tempValues);
		setValue('from', Number(tempValues.to) + 1);
		setValue('to', 0);
		setValue('grade', '');
	};

	return (
		<form>
			{/* <DevTool control={control} /> */}
			<input hidden {...register('from')} />
			<StyledOuterContainer alignItems={'center'}>
				<StyledInnerContainer>
					<EdFormControlLabel minWidth={'5rem'}>
						Range {fields.length + 1}:
					</EdFormControlLabel>
					<StyledLabel>From {fromGrade}</StyledLabel>
					<EdFormControl
						control={control}
						name={'from'}
						hidden
						defaultValue={fromGrade}
						value={fromGrade}
						label={'from'}
						showLabel={false}
						disabled={disabled}
					/>
					<StyledLabel>To</StyledLabel>
					<EdFormControl
						width="80px"
						control={control}
						name={`to`}
						label="to"
						showLabel={false}
					/>
					<StyledLabel>%</StyledLabel>
					<EdFormControl
						labelProps={{ minWidth: '5rem' }}
						width={'11.25rem'}
						control={control}
						name={`grade`}
						label="Grade:"
						disabled={disabled}
					/>
				</StyledInnerContainer>
			</StyledOuterContainer>
			<EdButton
				edcolor="dodgerBlue"
				onClick={handleSubmit(onAdd)}
				variant="outlined"
				disabled={disabled}
			>
				SAVE RANGE
			</EdButton>
		</form>
	);
};

export default GradeOptionsStartFrom;
