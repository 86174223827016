import { BaseTinyMceConfig } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Constants/Questions.config';
import { uploadPicture } from '@Services/uploads/uploads.req';
import { Editor, IAllProps } from '@tinymce/tinymce-react';
import React from 'react';

type Props = { min_height?: number ; max_height ?:number } & IAllProps;
const TinyMceControl: React.FC<Props> = (props) => {
	return (
		<Editor
			apiKey="x25t3bmtfeey2dswgf48d0ut1o44yjmajktf3mb6r5exjk1y"
			{...BaseTinyMceConfig}
			init={{
				...BaseTinyMceConfig.init,
				min_height: props.min_height ?? 400,
				max_height : props.max_height,
				images_upload_handler: async (blobInfo) => {
					const {
						data: {
							data: { uri },
						},
					} = await uploadPicture({
						folder: 'tinymc',
						photo: blobInfo.blob(),
					});
					return uri;
				},
			}}
			{...props}
		/>
	);
};

export default TinyMceControl;
