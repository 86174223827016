import { useTypedSelector } from '@Features/store';
import { InvoicesRequester } from '@Pages/Invoices/Services/Invoices.req';
import { invoicesGetSingle } from '@Pages/Invoices/Slices/Invoices.slice';
import { useSnackbar } from '@Providers/useSnackbar';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { CardContainer } from '@Pages/ClassRooms/Pages/AddClassroom/AddClassroom';
import { FlexLayout, GridLayout } from '@Styled/utilities';
import {
	BackButton,
	IconStyle,
	ProcessBtn,
} from '@Pages/Invoices/Pages/SingleInvoice/SingleInvoice';
import InvoicesCard from '@Pages/Invoices/Pages/SingleInvoice/InvoicesCard';
import TransactionCard from '@Pages/Invoices/Pages/SingleInvoice/TransactionCard';
import CourseCard from '@Pages/Invoices/Pages/SingleInvoice/CourseCard';

const SingleStudentInvoice = () => {
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const history = useHistory();
	const { displaySnackbar } = useSnackbar();

	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.Invoices);

	useEffect(() => {
		dispatch(invoicesGetSingle({ id: Number(id) }));
	}, []);

	const onProcess = async () => {
		try {
			await InvoicesRequester.getInstance().process(Number(id));
			displaySnackbar('success', `Invoice Processed Successfully`);
			dispatch(invoicesGetSingle({ id: Number(id) }));
		} catch (e) {
			const msg = e.response?.data.message || 'Unable to Process Invoice ';
			displaySnackbar('error', msg);
		}
	};

	return (
		<>
			{currentEntity && getSingle === 'fulfilled' && (
				<CardContainer>
					<FlexLayout justifyContent="space-between">
						<BackButton
							alignItems="center"
							justifyContent="center"
							onClick={() => history.goBack()}
						>
							<IconStyle />
						</BackButton>
						<ProcessBtn variant="contained" onClick={() => onProcess()}>
							Process
						</ProcessBtn>
					</FlexLayout>
					<GridLayout gridArea="1.5rem" gridGap="1.5rem">
						<InvoicesCard />
						<TransactionCard />
					</GridLayout>
					{currentEntity.type === 'purchase' &&
						currentEntity?.courses.length > 0 && <CourseCard />}
				</CardContainer>
			)}
		</>
	);
};

export default SingleStudentInvoice;
